<template>
  <div class="flex flex-col gap-3 modal-center-content">
    <div class="flex justify-between items-center w-full">
      <strong class="text-md">{{ $t("license_modal.license") }}</strong>
      <span class="text-md">{{ $t("license_modal.available_license") }}</span>
    </div>
    <div class="flex justify-between items-center w-full">
      <strong class="text-md">{{ periodTitle }}</strong>
      <span class="text-md">{{ license.limitDate }}</span>
    </div>
    <div class="flex w-full items-center justify-between">
      <strong>{{ fullAmount }}</strong>
      <div class="flex justify-between items-center gap-4">
        <button
          v-if="license.cancelAtPeriodEnd"
          type="is-ghost"
          class="text-center font-medium rounded link-button"
          @click="showReactivateSubscription()"
        >
          {{ $t("list_licenses.reactive_license") }}
        </button>
        <button v-else @click="showDeleteLicense()">
          <img class="img-icon" src="@/assets/icons/mi_delete.svg" />
        </button>
        <button
          class="bg-black text-white shadow hover:shadow-lg rounded font-semibold px-3 py-1"
          @click="$emit('assign')"
        >
          {{ $t("license_modal.assign_license") }}
        </button>
      </div>
    </div>
    <confirmation-modal
      :show="deleteLicenseModal"
      :title="$t('list_licenses.title_cancel_license')"
      :cancel-text="$t('close')"
      :accept-text="$t('list_licenses.cancel_license')"
      @cancel="deleteLicenseModal = false"
      @accept="cancelSubscription()"
    >
      <div class="confirmation-modal-content">
        <p>
          {{ $t("list_licenses.doYouCancel") }}
        </p>
        <p>{{ $t("site_options_menu.doYouCancel2") }}</p>
      </div>
    </confirmation-modal>
    <confirmation-modal
      :show="reactiveLicense"
      :title="$t('list_licenses.title_reactive_license')"
      :cancel-text="$t('close')"
      :accept-text="$t('list_licenses.reactive_license')"
      accept-text-style-type="dark"
      @cancel="reactiveLicense = false"
      @accept="reactivateSubscription()"
    >
      <div class="confirmation-modal-content">
        {{ $t("list_licenses.ask_reactivate_subscription") }}
      </div>
    </confirmation-modal>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>
<style lang="scss" scoped>
.link-button {
  border: 0;
  padding: 0;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
  }
}
.confirmation-modal-content {
  min-height: 50px;
  min-width: 350px;
  text-align: left;
  justify-content: center;
  align-content: center;
}
</style>
<script>
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import ApiPayments from "@/services/payments";
import ApiLicenses from "@/services/licenses";
import ApiSites from "@/services/sites";
export default {
  name: "ListLicenses",
  components: {
    ConfirmationModal
  },

  props: {
    license: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      deleteLicenseModal: false,
      reactiveLicense: false,
      fullAmount: `${this.license.totalAmount} ${this.license.currency}/${this.license.plan}`,
      isLoading: false
    };
  },

  computed: {
    periodTitle() {
      return this.license.cancelAtPeriodEnd
        ? this.$t("list_licenses.end_date")
        : this.$t("license_modal.payment");
    }
  },

  methods: {
    showDeleteLicense() {
      return (this.deleteLicenseModal = !this.deleteLicenseModal);
    },
    showReactivateSubscription() {
      return (this.reactiveLicense = !this.reactiveLicense);
    },
    async reactivateSubscription() {
      try {
        this.isLoading = true;
        const { subscriptionId } = this.license;
        await ApiPayments.reactivateSubscription(subscriptionId, {
          reactivationDate: Date.now()
        });
        const formatedMessage = String(
          this.$t("success_messages.reactivated_license")
        );
        this.$buefy.notification.open({
          message: formatedMessage,
          type: "is-link",
          duration: 5000
        });
        const websiteRes = await ApiSites.findMyWebsites();
        this.$store.dispatch("setWebsites", websiteRes.data || []);
        const licenseRes = await ApiLicenses.availableLicenses();
        this.$store.dispatch("setLicenses", licenseRes.data || []);
      } catch (error) {
        this.isLoading = false;
        this.reactiveLicense = false;
        this.$buefy.notification.open({
          message:
            error?.message || this.$t("error_messages.reactivated_license"),
          type: "is-danger"
        });
      } finally {
        this.isLoading = false;
        this.reactiveLicense = false;
      }
    },
    async cancelSubscription() {
      try {
        this.isLoading = true;
        const { subscriptionId } = this.license;
        await ApiPayments.cancelSubscription({
          subscriptionId
        });
        const formatedMessage = String(
          this.$t("subscription_modal.canceled_successfully") || ""
        ).replace(/{{date}}/, this.license.limitDate);
        this.$buefy.notification.open({
          message: formatedMessage,
          type: "is-link",
          duration: 10000
        });
        const licensesRes = await ApiLicenses.availableLicenses();
        this.$store.dispatch("setLicenses", licensesRes.data || []);
        const websiteRes = await ApiSites.findMyWebsites();
        this.$store.dispatch("setWebsites", websiteRes.data || []);
      } catch (error) {
        this.isLoading = false;
        this.deleteLicenseModal = false;
        this.$buefy.notification.open({
          message:
            error?.message || this.$t("error_messages.cancel_subscription"),
          type: "is-danger"
        });
      } finally {
        this.isLoading = false;
        this.deleteLicenseModal = false;
      }
    }
  }
};
</script>
