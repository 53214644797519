<template>
  <div class="pb-8 mt-8 main-container flex flex-grow">
    <div
      class="flex flex-grow flex-wrap justify-center modal-content-container"
    >
      <div class="modal-center-content flex flex-col flex-1 gap-6">
        <span class="text-md text-center">
          {{ $t("assign_website_license.subtitle") }}
        </span>
        <b-field class="mt-2" :label="$t('assign_website_license.label_title')">
          <b-dropdown
            ref="dropdown"
            v-model="selectedWebsite"
            max-height="150px"
            :disabled="freeWebsites.length === 0"
            :scrollable="true"
            :mobile-modal="false"
            expanded
          >
            <template #trigger>
              <button
                class="select-container rounded-md p-2"
                :class="!selectedWebsite ? 'is-hovered' : ''"
                expanded
              >
                <span>{{ dropDownTitle }}</span>
                <div class="inline-flex items-center">
                  <a
                    v-if="selectedWebsite"
                    @click.prevent.stop="reSelectedWebsite"
                  >
                    <img src="@/assets/icons/cross-close.svg" />
                  </a>
                  <a class="ml-2">
                    <img
                      src="@/assets/icons/chevron-right.svg"
                      class="text-black"
                      width="24"
                      height="24"
                    />
                  </a>
                </div>
              </button>
            </template>
            <b-dropdown-item
              v-for="website in freeWebsites"
              :key="website.websiteId"
              :value="website"
            >
              {{ website.name }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
        <div class="flex flex-col items-center mt-auto">
          <b-button
            type="is-dark"
            class="font-medium rounded mt-4 px-4 w-60"
            :disabled="!selectedWebsite || isLoading"
            @click="assignWebsite()"
          >
            <div class="flex items-center justify-center">
              <b-icon
                v-if="isLoading"
                pack="fas"
                icon="sync-alt"
                size="is-small"
                custom-class="fa-spin"
              >
              </b-icon>
              <span>{{ savingTitle }}</span>
            </div>
          </b-button>
          <b-button
            type="is-ghost"
            class="text-center font-medium rounded mt-4 link-button w-60"
            @click="$emit('returnLicense')"
          >
            {{ $t("subscription_modal.return") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-container {
  min-height: 440px;
}
.modal-content-container {
  max-width: 640px;
  width: 100%;
  .modal-center-content {
    max-width: 500px;
    width: 100%;
  }
}
.is-hovered {
  color: #a7a6ac;
  background-color: inherit;
  border-color: inherit;
}
.dropdown-item.is-active,
.dropdown-item:hover {
  background-color: transparent;
  color: inherit;
}
.select-container {
  position: relative;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;
  transition: box-shadow 0.2s ease;
}
.link-button {
  border: 0;
  padding: 0;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
    background-color: #e2edff;
  }
}
</style>
<style>
.dropdown .dropdown-menu .dropdown-content {
  border: none;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
}
.dropdown .dropdown-menu {
  transition: all 0.1s ease-in;
  opacity: 0;
  transform: translateY(-20px);
}
.dropdown.is-active .dropdown-menu {
  opacity: 1;
  transform: translateY(0px);
  height: auto;
}
</style>
<script>
import { mapGetters } from "vuex";
import ApiLicenses from "@/services/licenses";
import ApiSites from "@/services/sites";
import SuccessIcon from "@/assets/icons/sucess-icon.svg";
export default {
  name: "AssignWebsiteLicense",

  props: {
    currentLicense: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      selectedWebsite: null,
      isLoading: false
    };
  },

  computed: {
    ...mapGetters(["freeWebsites"]),
    dropDownTitle() {
      if (this.selectedWebsite) return this.selectedWebsite.name;
      else if (this.freeWebsites.length === 0)
        return this.$t("assign_website_license.no_free_websites_input");
      else return this.$t("assign_website_license.default_value_input");
    },
    savingTitle() {
      if (!this.isLoading) return this.$t("assign_website_license.save");
      else return this.$t("assign_website_license.saving");
    }
  },

  methods: {
    reSelectedWebsite() {
      this.selectedWebsite = null;
      this.$refs.dropdown.isActive = false;
    },
    async assignWebsite() {
      try {
        this.isLoading = true;
        const { websiteId, name } = this.selectedWebsite;
        await ApiLicenses.assignWebsite(this.currentLicense, websiteId);
        await ApiSites.upgrade({ websiteId });
        const licenses = await ApiLicenses.availableLicenses();
        this.$store.dispatch("setLicenses", licenses.data || []);
        const websites = await ApiSites.findMyWebsites();
        this.$store.dispatch("setWebsites", websites.data || []);
        const message = `<div style="display:flex;align-items:center;"><img src="${SuccessIcon}" alt="img" style="display:inline-block;margin-right: 8px;" />${this.$t(
          "assign_website_license.successfully_assign_website"
        ).replace(/{name}/, name)}</div>`;
        this.$buefy.snackbar.open({
          message,
          position: "is-bottom"
        });
      } catch (error) {
        this.isLoading = false;
        const { name } = this.selectedWebsite;
        this.$buefy.toast.open({
          message: this.$t("error_messages.assign_license_website").replace(
            /{name}/,
            name
          ),
          type: "is-danger",
          position: "is-bottom"
        });
      } finally {
        this.isLoading = false;
        this.$emit("closeModal");
      }
    }
  }
};
</script>
