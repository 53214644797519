<template>
  <div class="pb-16 mt-8 container">
    <div
      class="flex flex-grow flex-wrap justify-center modal-content-container"
    >
      <div class="modal-center-content flex flex-col">
        <strong class="text-md">
          {{ $t("license_modal.available_licenses_pro") }}
        </strong>
        <span class="my-4">
          {{ totalProLicencesAvailables }}
        </span>
        <div class="divider"></div>
        <div
          class="flex gap-8 py-6 flex-grow flex-wrap justify-center modal-content-container max-h-72 overflow-y-scroll"
        >
          <slot />
        </div>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.container {
  min-height: 440px;
}

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
#id-div-suscription {
  min-height: 512px;
}
.divider {
  width: 100%;
  border-bottom: 1px solid #bebec2;
}
.modal-content-container {
  max-width: 640px;
  width: 100%;
  .modal-center-content {
    max-width: 500px;
    width: 100%;
  }
}
.img-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.imgSmall {
  width: 32px;
  height: 32px;
  max-width: none !important;
  cursor: pointer;
}
.modal-container {
  max-width: 900px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.link-button {
  border: 0;
  padding: 0;
  width: auto;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
  }
}
.border-red {
  border: 1px solid red;
}
.border-blue {
  border: 1px solid blue;
}
.border-green {
  border: 1px solid green;
}
@media (max-width: 640px) {
  .link-button {
    width: 100%;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "LicenseModal",

  computed: {
    ...mapGetters(["totalProLicencesAvailables"])
  }
};
</script>
