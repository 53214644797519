<template>
  <b-modal
    :active="activate"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    scroll="keep"
    class="flex flex-wrap"
    @close="handleClose()"
  >
    <div class="modal-card modal-container">
      <section class="modal-card-body flex flex-grow flex-col rounded">
        <div class="flex mb-3 relative">
          <div class="flex justify-center flex-grow font-bold">
            <h1 class="title is-4">{{ title }}</h1>
          </div>
          <div class="close-icon">
            <img
              class="flex grow-0 close-btn items-center imgSmall"
              src="@/assets/icons/rounded-cross.svg"
              @click="handleClose()"
            />
          </div>
        </div>
        <available-licenses v-if="showAvailableLicenses">
          <template v-if="getLicenses.length > 0">
            <list-licenses
              v-for="license in getLicenses"
              :key="license.id"
              :license="license"
              @assign="assignCurrentLicense(license.id)"
            />
          </template>
          <template v-else>
            <div class="w-full inline-flex flex-col gap-2 mb-4">
              <strong>{{ $t("license_modal.title") }}</strong>
              <span>{{ $t("license_modal.no_available_licenses") }}</span>
            </div>
          </template>
        </available-licenses>
        <assign-website-license
          v-else
          :current-license="currentLicense"
          @closeModal="handleClose()"
          @returnLicense="changeModal()"
        />
      </section>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
#id-div-suscription {
  min-height: 512px;
}
.divider {
  width: 100%;
  border-bottom: 1px solid #bebec2;
}
.modal-content-container {
  max-width: 640px;
  width: 100%;
  .modal-center-content {
    max-width: 500px;
    width: 100%;
  }
}
.img-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.imgSmall {
  width: 32px;
  height: 32px;
  max-width: none !important;
  cursor: pointer;
}
.modal-container {
  max-width: 900px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.link-button {
  border: 0;
  padding: 0;
  width: auto;
  color: #0a69ff;
  &:hover {
    color: #2b7cfe;
  }
}
.border-red {
  border: 1px solid red;
}
.border-blue {
  border: 1px solid blue;
}
.border-green {
  border: 1px solid green;
}
@media (max-width: 640px) {
  .link-button {
    width: 100%;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import AssignWebsiteLicense from "./AssignWebsiteLicense.vue";
import AvailableLicenses from "./AvailableLicenses.vue";
import ListLicenses from "./ListLicenses.vue";
export default {
  name: "LicenseModal",

  components: {
    AssignWebsiteLicense,
    AvailableLicenses,
    ListLicenses
  },

  props: {
    activate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentLicense: "",
      showAvailableLicenses: true
    };
  },
  computed: {
    ...mapGetters(["getLicenses"]),
    title() {
      return this.showAvailableLicenses
        ? this.$t("license_modal.title")
        : this.$t("assign_website_license.title");
    }
  },
  methods: {
    changeModal() {
      this.showAvailableLicenses = !this.showAvailableLicenses;
    },
    assignCurrentLicense(license) {
      this.currentLicense = license;
      this.changeModal();
    },
    handleClose() {
      this.$emit("close");
      setTimeout(() => {
        this.showAvailableLicenses = true;
      }, 200);
    }
  }
};
</script>
